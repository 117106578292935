// Next already add polyfills, so be sure it is not listed in the link below before adding new ones
// https://github.com/zeit/next.js/blob/canary/packages/next-polyfill-nomodule/src/index.js
// https://github.com/vercel/next.js/issues/13231
import '@webcomponents/shadydom';
import 'core-js/features/promise/finally';
import 'core-js/features/symbol';
import smoothscroll from 'smoothscroll-polyfill';

import 'core-js/features/array/includes';
import 'polyfill-array-includes';

// IE11 mandatory
smoothscroll.polyfill();

// Create Element.remove() function if not exist
if (!('remove' in Element.prototype)) {
  Element.prototype.remove = function() {
    if (this.parentNode) {
      this.parentNode.removeChild(this);
    }
  };
}

(function() {
  if (typeof window.CustomEvent === 'function') return false; // If not IE

  function CustomEvent(event, params) {
    params = params || { bubbles: false, cancelable: false, detail: undefined };
    const evt = document.createEvent('CustomEvent');
    evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
    return evt;
  }

  CustomEvent.prototype = window.Event.prototype;

  window.CustomEvent = CustomEvent;
})();
